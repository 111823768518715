import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useEffect } from 'react'
import LocomotiveScroll from 'locomotive-scroll'
import { CustomUniforms } from 'src/_helpers/CustomUniforms'

gsap.registerPlugin(ScrollTrigger)

export default function useLocoScroll(x) {
  useEffect(() => {
    CustomUniforms.ls = null

    const scrollEl = document.querySelector('.ss')

    CustomUniforms.ls = new LocomotiveScroll({
      el: scrollEl,
      smooth: true,
      lerp: 0.015,
      multiplier: 1.5,
      touchMultiplier: 1.5,
      tablet: {
        smooth: true,
        lerp: 1.0
      },
      smartphone: {
        touchMultiplier: 6.5,
        smooth: true,
        lerp: 1.0
      }
    })

    CustomUniforms.ls.on('scroll', ScrollTrigger.update)

    ScrollTrigger.scrollerProxy(scrollEl, {
      scrollTop(value) {
        if (CustomUniforms.ls) {
          return arguments.length ? CustomUniforms.ls.scrollTo(value, 0, 0) : CustomUniforms.ls.scroll.instance.scroll.y
        }
        return null
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight
        }
      },

      pinType: scrollEl.style.transform ? 'transform' : 'fixed'
    })

    const lsUpdate = () => {
      if (CustomUniforms.ls) {
        CustomUniforms.ls.update()
      }
    }

    // locoScroll.stop()

    // if (start) {
    //   locoScroll.start()
    // }

    ScrollTrigger.addEventListener('refresh', lsUpdate)
    ScrollTrigger.refresh()

    return () => {
      if (CustomUniforms.ls) {
        ScrollTrigger.removeEventListener('refresh', lsUpdate)
        CustomUniforms.ls.destroy()
        CustomUniforms.ls = null
        CustomUniforms.ls.log('Kill', CustomUniforms.ls)
      }
    }
  })
}
