import React from 'react'

import useLocoScroll from 'src/_helpers/useLocoScroll.js'
import { Canvas } from '@react-three/fiber'

import loadable from '@loadable/component'
import MediaQuery from 'react-responsive'

function App() {
  useLocoScroll()

  const Content = loadable(() => import('./Components/Content/Content.js'))
  const Canvas3D = loadable(() => import('./Components/Canvas3D/Canvas3D.js'))
  const BGPlane = loadable(() => import('./Components/Box/Box.js'))
  const Overlay = loadable(() => import('./Components/Overlay/Overlay.js'))

  return (
    <main id="main-container">
      <Overlay />
      <div className="content-wrapper ss preload" id="container" data-scroll-section>
        <Content />
        <Canvas3D />
      </div>
    </main>
  )
}

export default App
